import { LitElement, html, css } from "lit";

class ReviewWidget extends LitElement {
  static properties = {
    widgetId: { type: String, reflect: true },
    activeTab: { type: String, reflect: true },
    displayedReviewsCount: { type: Number },
    loading: { type: Boolean },
    overallRating: { type: String },
    totalReviews: { type: Number },
  };

  static styles = css`
    :host {
      display: block;
      padding: 16px;
      background: #f9f9f9;
      box-sizing: border-box;
    }
    .tabs {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;
      flex-wrap: wrap;
    }
    .tab {
      padding: 8px 16px;
      border-radius: 16px;
      cursor: pointer;
      text-transform: uppercase;
      background: #e0e0e0;
      transition: background 0.3s, color 0.3s;
      font-weight: bold;
    }
    .tab.active {
      background: var(--active-tab-bg, #007bff);
      color: #fff;
    }
    .reviews-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 16px;
    }
    .review-card {
      background: #fff;
      padding: 20px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      transition: transform 0.3s;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .review-card:hover {
      transform: translateY(-1px);
    }
    .reviewer-info {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;
    }
    .review-card img,
    .default-user-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-bottom: 8px;
    }
    .review-card h3 {
      margin: 0 0 8px 0;
      font-size: 18px;
      color: #333;
    }
    .review-card p {
      margin: 8px 0;
      color: #555;
      line-height: 1.5;
    }
    .review-card .stars {
      color: #f5b50a;
      margin-bottom: 8px;
    }
    .default-user-icon {
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .review-text {
      overflow: hidden;
      max-height: 110px;
      text-overflow: ellipsis;
      position: relative;
    }
    .review-text.expanded {
      max-height: none;
    }
    .read-more {
      color: var(--active-tab-bg, #007bff);
      cursor: pointer;
      font-weight: bold;
      text-align: right;
    }
    .load-more {
      text-align: center;
      margin-top: 20px;
    }
    .load-more button {
      padding: 10px 20px;
      border: none;
      background-color: var(--active-tab-bg, #007bff);
      color: white;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
    }
    .write-review {
      text-align: right;
      margin-top: 20px;
    }
    .write-review a {
      padding: 10px 20px;
      background-color: var(--active-tab-bg, #007bff);
      color: white;
      font-size: 16px;
      text-decoration: none;
      border-radius: 5px;
      display: inline-block;
    }
    .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    .rating-summary {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .rating-summary .stars {
      font-size: 24px;
      color: #f5b50a;
    }
    .overall-rating {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left-color: var(--active-tab-bg, #007bff);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  constructor() {
    super();
    this.widgetId = "";
    this.activeTab = "";
    this.apiEndpoint = "https://api-inspired-4e322fa633ca.herokuapp.com";
    // this.apiEndpoint = "http://localhost:3000";
    this.reviews = {
      gmb: { reviews: [], url: "" },
      yelp: { reviews: [], url: "" },
      homeadvisor: { reviews: [], url: "" },
      angi: { reviews: [], url: "" },
      thumbtack: { reviews: [], url: "" },
      bbb: { reviews: [], url: "" },
    };
    this.displayedReviewsCount = 12;
    this.loading = false;
    this.overallRating = "0.0";
    this.totalReviews = 0;
  }

  updated(changedProperties) {
    if (changedProperties.has("widgetId")) {
      console.log(`Updated widgetId: ${this.widgetId}`);
      this.fetchReviews();
    }
  }

  async fetchReviews() {
    if (!this.apiEndpoint || !this.widgetId) {
      throw new Error("Missing apiEndpoint or widgetId");
    }
    const url = `${this.apiEndpoint}/get-reviews/${this.widgetId}`;
    this.loading = true;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.reviews = data.reviews || {
        gmb: { reviews: [], url: "" },
        yelp: { reviews: [], url: "" },
        homeadvisor: { reviews: [], url: "" },
        angi: { reviews: [], url: "" },
        thumbtack: { reviews: [], url: "" },
        bbb: { reviews: [], url: "" },
      };
      this.calculateOverallRating();
      this.setDefaultTab();
    } catch (error) {
      console.error("Error fetching reviews:", error);
      this.reviews = {
        gmb: { reviews: [], url: "" },
        yelp: { reviews: [], url: "" },
        homeadvisor: { reviews: [], url: "" },
        angi: { reviews: [], url: "" },
        thumbtack: { reviews: [], url: "" },
        bbb: { reviews: [], url: "" },
      };
    } finally {
      this.loading = false;
      this.requestUpdate();
    }
  }

  calculateOverallRating() {
    const allReviews = [
      ...this.reviews.gmb.reviews,
      ...this.reviews.yelp.reviews,
      ...this.reviews.homeadvisor.reviews,
      ...this.reviews.angi.reviews,
      ...this.reviews.thumbtack.reviews,
      ...this.reviews.bbb.reviews,
    ];
    const totalStars = allReviews.reduce((acc, review) => {
      const starRating = review.stars.includes("stars")
        ? parseInt(review.stars.split(" ")[0])
        : parseInt(review.stars);
      return acc + starRating;
    }, 0);
    this.totalReviews = allReviews.length;
    this.overallRating =
      this.totalReviews > 0
        ? (totalStars / this.totalReviews).toFixed(1)
        : "0.0";
  }

  setDefaultTab() {
    const tabs = ["gmb", "yelp", "homeadvisor", "angi"];
    for (let tab of tabs) {
      if (this.reviews[tab] && this.reviews[tab].reviews.length > 0) {
        this.activeTab = tab;
        return;
      }
    }
    this.activeTab = tabs[0]; // Si no hay reseñas en ninguno, default a 'gmb'
  }

  handleTabClick(tab) {
    this.activeTab = tab;
    this.displayedReviewsCount = 12;
  }

  toggleReadMore(e) {
    const reviewText = e.target.previousElementSibling;
    reviewText.classList.toggle("expanded");
    e.target.textContent = reviewText.classList.contains("expanded")
      ? "Hide"
      : "Read more";
  }

  loadMoreReviews() {
    this.displayedReviewsCount += 12;
  }

  renderTabs(reviews) {
    const tabs = ["gmb", "yelp", "homeadvisor", "angi", "thumbtack", "bbb"];
    return tabs.map((tab) => {
      const avgStars = this.calculateAverageStars(reviews[tab].reviews);
      return reviews[tab] && reviews[tab].reviews.length > 0
        ? html`<div
            class="tab ${this.activeTab === tab ? "active" : ""}"
            @click=${() => this.handleTabClick(tab)}
          >
            ${tab.charAt(0).toUpperCase() + tab.slice(1)} Reviews
            (${avgStars}⭐)
          </div>`
        : "";
    });
  }

  calculateAverageStars(reviews) {
    if (!reviews || reviews.length === 0) return 0;
    const totalStars = reviews.reduce((acc, review) => {
      const starRating = review.stars.includes("stars")
        ? parseInt(review.stars.split(" ")[0])
        : parseInt(review.stars);
      return acc + starRating;
    }, 0);
    return (totalStars / reviews.length).toFixed(1);
  }

  renderStars(stars) {
    const starCount = parseInt(stars, 10);
    return html`${"⭐".repeat(starCount)} ${starCount}.0`;
  }

  renderReviews(reviews) {
    if (
      !reviews[this.activeTab] ||
      !reviews[this.activeTab].reviews ||
      reviews[this.activeTab].reviews.length === 0
    ) {
      return html`<p>No reviews available for ${this.activeTab}</p>`;
    }

    return reviews[this.activeTab].reviews
      .slice(0, this.displayedReviewsCount)
      .map(
        (review) => html`
          <div class="review-card">
            <div class="reviewer-info">
              ${review.profilePic
                ? html`<img src="${review.profilePic}" alt="Profile" />`
                : html`<div class="default-user-icon">👤</div>`}
              <div>
                <h3>${review.author || "Anonymous"}</h3>
                <p>${review.time}</p>
              </div>
            </div>
            <div>
              <div class="stars">${this.renderStars(review.stars)}</div>
            </div>
            ${review.review
              ? html`
                  <div class="review-text">${review.review}</div>
                  ${review.review.length > 200
                    ? html`
                        <div class="read-more" @click=${this.toggleReadMore}>
                          Read more
                        </div>
                      `
                    : ""}
                `
              : ""}
          </div>
        `
      );
  }

  render() {
    const currentTab = this.reviews[this.activeTab] || { reviews: [], url: "" };
    return html`
      <div class="header">
        <div class="tabs">${this.renderTabs(this.reviews)}</div>
        ${currentTab.url
          ? html`<div class="write-review">
              <a href="${currentTab.url}" target="_blank">Write a Review</a>
            </div>`
          : ""}
      </div>
      ${this.loading
        ? html`<div class="spinner"></div>`
        : html`
            <div class="overall-rating">
              Overall Rating: ${this.overallRating} ⭐ (${this.totalReviews}
              reviews)
            </div>
            <div class="reviews-grid">${this.renderReviews(this.reviews)}</div>
            ${currentTab.reviews.length > this.displayedReviewsCount
              ? html`
                  <div class="load-more">
                    <button @click=${this.loadMoreReviews}>Load More</button>
                  </div>
                `
              : ""}
          `}
    `;
  }
}

customElements.define("review-widget", ReviewWidget);
